import React from "react";
import ReactComponent from "../app/ReactComponent"
import {Button, Card, Col, DatePicker, Form, Result, Row, Select, Space, Spin, Layout, Tooltip, Alert} from "antd";
import dayjs from "dayjs";
import Query from '../app/Query'
import {
  getBookingStoreNameByResourceRecordId,
  getBookingTicketNameByTicketId,
  mapBookings,
  mapStores
} from "../services/bookings";

import {prettyDate} from "../helpers/utils";
import {pushAnalytics} from "../helpers/global"


import {
  PROPERTY_BOOKINGS_STORE_KEY,
  PROPERTY_BOOKINGS_CLOSEST_ALLOWED_DATE_KEY,
  PROPERTY_BOOKINGS_BLOCKED_DONOR_KEY, PROPERTY_VALIDITY_OF_YEARLY_CHECKUP_KEY
} from '../helpers/properties.js'

import {
  AP_BOOKING_STATUS_ARRIVED_ID,
  AP_BOOKING_STATUS_ACTIVE_ID,
  AP_BOOKING_STATUS_ARRIVED_ON_TIME_ID,
  AP_BOOKING_STATUS_RESOLVED_ID, AP_YEARLY_CHECKUP_BOOKING_TICKET_ID, AP_BOOKING_TICKET_REGULAR_BLOOD_DONATION_ID
} from '../helpers/misc.js'
import {Option} from "antd/es/mentions";
import {getPartnerIdByStoreId, getStoreIdBySystemId} from "../services/stores";
import Cookies from "js-cookie";
import {SSID_COOKIE_NAME, UTM_COOKIE_NAME} from "../helpers/global";
import {createRef} from "react";
import App from "../app/App";
import {Link, Navigate} from "react-router-dom";
import {CloseOutlined, CaretDownOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {ReservationConfirm} from "../page/ReservationConfirm";
import Empty from 'antd/es/empty/empty'
import {ReservationTimeAvailability} from './ReservationTimeAvailability'

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const emptyData = <div className="empty-data"><Empty/>
  <div>Žádná data nejsou k dispozici</div>
</div>

export class ReservationForm extends ReactComponent {
  ticketInput = createRef()
  reservationFormRef = createRef()

  constructor(props) {
    super(props);

    this.state = {
      constants: {
        AP_BOOKING_STATUS_ARRIVED_ID,
        AP_BOOKING_STATUS_ACTIVE_ID,
        AP_BOOKING_STATUS_RESOLVED_ID,
        AP_BOOKING_STATUS_ARRIVED_ON_TIME_ID
      },
      loading_page: false,
      loading_timeslots: false,
      slots: {
        loading: false
      },
      booking_stores: [],
      booking_tickets: [],
      selected_store: false,
      selected_ticket: false,
      selected_time_slot: false,
      selected_date: null,
      time_slots: [],
      original_time_slots: [],
      previous_partners: [],
      previous_bookings: [],
      last_booking: null,
      partner_alert: false,
      blocked_alert: false,
      ongoing_alert: false,
      validity_of_yearly_checkup: null,
      yearly_checkup_necessary: false,
      min_date: dayjs(), // today
      complete: false,
      properties: [],
      stores: []
    }
  }

  async componentDidMount() {

    await this.getDataForForm()

  }

  async getDataForForm() {
    try {

      void this.updateState({loading_page: true})

      const query = (new Query('bookings'))
        .add('sort_field', 'created_at')
        .add('sort_direction', 'desc')
        .add('add_booking_items', 'full')
        .add('booking_statuses', [this.state.constants.AP_BOOKING_STATUS_RESOLVED_ID])
        .add('count', 1)

      const {bookings = []} = await this.$api({
        action: 'carecloud_api',
        route: query.route,
      })

      const {items: booking_stores = []} = await this.$api({
        action: 'carecloud_api',
        route: `booking-ticket-properties/${PROPERTY_BOOKINGS_STORE_KEY}`
      })

      const {booking_tickets = []} = await this.$api({
        action: 'carecloud_api',
        route: 'booking-tickets'
      })

      const properties = await this.$app.$store('account').getProperties()
      const stores = await this.$app.$store('common').getStores()

      await this.updateState({
        booking_tickets: booking_tickets,
        booking_stores: booking_stores,
        previous_bookings: mapBookings(bookings),
        stores: stores,
        properties: properties
      })


      // prevents new booking if any ongoing bookings exists
      this.checkForOngoingBookings()

      // collects partner ids from previous resolved bookings
      void this.setPreviousPartners()

      // checks closest allowed date property for logged customer and set if present
      this.checkForClosestAllowedDateProperty()

      // checks blocked property and prevents booking if present
      this.checkForBlockedDonorProperty()

      // preselect booking ticket if only one available
      this.setPreselectedBookingTicket()

      // preselect store based on cookie or last booking
      this.setPreselectedStore()

      // Set validity of yearly checkup
      const {property_value: validity_of_yearly_checkup = null} = this.state.properties.find(({property_id}) => property_id === PROPERTY_VALIDITY_OF_YEARLY_CHECKUP_KEY)

      void this.updateState({
        validity_of_yearly_checkup: dayjs(validity_of_yearly_checkup),
        loading_page: false
      })

    } catch (error) {

      this.handleError(error)
      //console.log(error)

    }
  }

  mapTimeSlots(time_slots) {

    return time_slots.reduce((acc, time_slot) => {

      const date_instance = dayjs(time_slot.valid_from)
      const date_hour = date_instance.hour()

      Object.assign(time_slot, {
        range: date_instance.format('HH:mm')
      })

      if (date_hour >= 7 && date_hour < 10) acc.groups.morning.push(time_slot)
      else if (date_hour >= 10 && date_hour < 12) acc.groups.noon.push(time_slot)
      else acc.groups.afternoon.push(time_slot)

      acc.total++

      return acc

    }, {
      groups: {
        morning: [],
        noon: [],
        afternoon: []
      },
      total: 0
    })

  }

  async checkForOngoingBookings() {

    const ongoing_statuses = [
      this.state.constants.AP_BOOKING_STATUS_ARRIVED_ID,
      this.state.constants.AP_BOOKING_STATUS_ARRIVED_ON_TIME_ID,
      this.state.constants.AP_BOOKING_STATUS_ACTIVE_ID
    ]

    const query = (new Query('bookings'))
      .add('sort_field', 'created_at')
      .add('sort_direction', 'desc')
      .add('add_booking_items', 'full')
      .add('booking_statuses', ongoing_statuses)

    const {bookings = []} = await this.$api({
      action: 'carecloud_api',
      route: query.route,
    })

    const ongoing_bookings = mapBookings(bookings)

    if (ongoing_bookings.length)
      void this.updateState({ongoing_alert: true})
  }

  checkForBlockedDonorProperty() {

    const {property_value = false} = (this.state.properties.find(({property_id}) => property_id === PROPERTY_BOOKINGS_BLOCKED_DONOR_KEY) ?? {})

    if (property_value)
      this.setState(prevState => ({
        ...prevState,
        blocked_alert: true
      }))

  }

  checkForClosestAllowedDateProperty() {

    const {property_value = false} = (this.state.properties.find(({property_id}) => property_id === PROPERTY_BOOKINGS_CLOSEST_ALLOWED_DATE_KEY) ?? {})

    // if date is set and is same or after today, not before
    if (property_value && dayjs(property_value).isSameOrAfter(dayjs())) {
      void this.updateState({
        selected_date: property_value,
        min_date: dayjs(property_value)
      })
    }
  }

  inputDatePickerDisabledDate(current) {

    if (this.state.selected_ticket === AP_YEARLY_CHECKUP_BOOKING_TICKET_ID) {
      let min_date = this.state.validity_of_yearly_checkup.isBefore(this.state.min_date) ? this.state.min_date : this.state.validity_of_yearly_checkup

      return current < min_date

    } else
      
      return current < this.state.min_date

  }

  async checkForNecessaryYearlyCheckUp() {

    if (this.state.validity_of_yearly_checkup && this.state.selected_date
      && this.state.selected_date.isAfter(this.state.validity_of_yearly_checkup)
      && this.state.selected_ticket === AP_BOOKING_TICKET_REGULAR_BLOOD_DONATION_ID) {

      await this.updateState({yearly_checkup_necessary: true})

    }
  }

  setBookingToYearlyCheckUP() {

    this.reservationFormRef.current.setFieldValue("selected_ticket", AP_YEARLY_CHECKUP_BOOKING_TICKET_ID)
    this.reservationFormRef.current.setFieldValue('selected_date', null)

    void this.updateState({
      yearly_checkup_necessary: false,
      selected_ticket: AP_YEARLY_CHECKUP_BOOKING_TICKET_ID,
      selected_date: null
    })

    this.onFilterChange()

  }

  checkForPreviousPartners() {

    if (!this.state.selected_store) return

    const partner_id = this.getPartnerId(this.state.selected_store)

    if (partner_id && !this.state.previous_partners.includes(partner_id))
      void this.updateState({
        partner_alert: true
      })

  }

  async setPreviousPartners() {

    const partners = []

    // closest resolved booking
    const previous_resolved_booking = this.state.previous_bookings.find(({booking_item_status}) => booking_item_status === this.state.constants.AP_BOOKING_STATUS_RESOLVED_ID)

    if (previous_resolved_booking) {

      const {additional_properties = []} = previous_resolved_booking

      const store_properties = additional_properties.filter(({property_id}) => property_id === PROPERTY_BOOKINGS_STORE_KEY)

      store_properties.forEach(({property_value: booking_stores = []}) => {

        booking_stores.forEach(({resource_record_id: store_id}) => {

          if (store_id) {

            const partner_id = this.getPartnerId(store_id)

            if (partner_id) partners.push(partner_id)

          }

        })

      })

    }

    this.setState(prevState => ({
      ...prevState,
      previous_partners: partners
    }))
  }

  setPreselectedBookingTicket() {


    if (this.state.booking_tickets.length > 0) {

        const {ticket_id = null} = (this.state.booking_tickets.find(item => item.ticket_id === AP_BOOKING_TICKET_REGULAR_BLOOD_DONATION_ID) ?? this.state.booking_tickets[0])

      if (ticket_id) {
        this.setState(prevState => ({
          ...prevState,
          selected_ticket: ticket_id
        }))
        this.reservationFormRef.current.setFieldValue("selected_ticket", ticket_id)
      }
    }
  }


  getPartnerId(id) {

    return getPartnerIdByStoreId(this.state.stores, id)

  }

  getOriginalTimeSlotValidFrom(id) {

    const {valid_from} = (this.state.original_time_slots.find(({time_slot_id}) => time_slot_id === id) ?? {})

    return valid_from

  }

  getBookingTicketName(id) {

    return getBookingTicketNameByTicketId(this.state.booking_tickets, id)

  }

  getBookingStoreName(id) {

    return getBookingStoreNameByResourceRecordId(this.state.booking_stores, id)

  }

  getDateTitle(str) {

    return prettyDate(str, '[<div class="mt-2 text-h4">]D.[</div><div class="text-h5 text-capitalize font-weight-light">]MMMM[</div><div class="text-h4">]YYYY[</div>]')

  }

  getDateDay(str) {

    return prettyDate(str, 'D')

  }


  reset() {

    void this.updateState({
      partner_alert: false,
      selected_store: false,
      selected_ticket: false,
      selected_time_slot: false,
      selected_date: dayjs().format('YYYY-MM-DD')
    })

    Cookies.remove(UTM_COOKIE_NAME)

  }

  resolveUTM() {

    const utm_cookie = Cookies.get(UTM_COOKIE_NAME)

    if (utm_cookie) return JSON.parse(utm_cookie)
    else return {}

  }

  setPreselectedStore() {

    let store_id = getStoreIdBySystemId(this.state.stores, Cookies.get(SSID_COOKIE_NAME), false)

    if(store_id === false && this.state.previous_bookings.length > 0) {

      const {property_value: booking_stores = []} = (this.state.previous_bookings[0].additional_properties.find(({property_id}) => property_id === PROPERTY_BOOKINGS_STORE_KEY ?? {}))

      store_id =  booking_stores[0].resource_record_id

    }


    if (store_id) {
      this.setState(prevState => ({
        ...prevState,
        selected_store: store_id
      }))

      this.reservationFormRef.current.setFieldValue("selected_store", store_id)

    }

  }

  async onFilterChange() {

    try {

      this.reservationFormRef.current.setFieldValue('selected_time_slot', null)

      await this.updateState({
        partner_alert: false,
        selected_time_slot: false,
        yearly_checkup_necessary: false,
      })

      await this.checkForNecessaryYearlyCheckUp()

      if (this.state.selected_store && this.state.selected_ticket && this.state.selected_date && !this.state.yearly_checkup_necessary) {

        void this.updateState({
          loading_timeslots: true
        })

        // displays alert when previous partners exists and selected partner is not one of them
        if (this.state.previous_partners.length) this.checkForPreviousPartners()

        void this.updateState({
          slots: {loading: true}
        })

        const date_instance = dayjs(this.state.selected_date)


        // if selected date is today, set time_from to now + 30 minutes
        const time_from = (date_instance.isToday() ? dayjs().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss') : date_instance.format('YYYY-MM-DD 00:00:01'))
        const time_to = date_instance.format('YYYY-MM-DD 23:59:59')

        const query = new Query(`booking-tickets/${this.state.selected_ticket}/time-slots`)

        query.add('time_from', time_from)
        query.add('time_to', time_to)
        query.add('booking_ticket_property_id', PROPERTY_BOOKINGS_STORE_KEY)
        query.add('booking_ticket_property_value', this.state.selected_store)
        query.add('sort_field', 'valid_from')
        query.add('sort_direction', 'ASC')
        query.add('count', 150)

        const {time_slots = []} = await this.$api({
          action: 'carecloud_api',
          route: query.route
        })

        this.setState(prevState => ({
          ...prevState,
          original_time_slots: time_slots,
          time_slots: this.mapTimeSlots(time_slots)
        }))

      }

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_timeslots: false})

    }

  }

  async onTicketChange(newTicket) {

    await this.updateState({
      selected_ticket: newTicket,
      //selected_store: null,
      selected_time_slot: false,
      selected_date: null,
      original_time_slots: [],
      time_slots: []
    })

    //this.reservationFormRef.current.setFieldValue('selected_store', null)
    this.reservationFormRef.current.setFieldValue('selected_date', null)
    this.reservationFormRef.current.setFieldValue('selected_time_slot', null)

    void this.onFilterChange()

  }

  async onStoreChange(newStore) {
    await this.updateState({
      selected_store: newStore,
      selected_time_slot: false,
      selected_date: null,
      original_time_slots: [],
      time_slots: []
    })

    this.reservationFormRef.current.setFieldValue('selected_date', null)
    this.reservationFormRef.current.setFieldValue('selected_time_slot', null)

    void this.onFilterChange()

  }

  async onDateChange(newDate) {

    await this.updateState({
      selected_date: newDate
    })

    void this.onFilterChange()

  }

  onTimeSlotChange(newTimeSlot) {
    void this.updateState({selected_time_slot: newTimeSlot})
  }

  async handleReservationFormSubmit(data) {

    void this.updateState({loading_page: true})

    try {

      if (!this.state.selected_store) throw 'Prosím vyberte odběrové centrum.'
      if (!this.state.selected_time_slot) throw 'Vyberte čas odběru.'

      const customer_id = this.props.$auth.getCustomerID()
      const created_at = dayjs().format('YYYY-MM-DD HH:mm:ss')

      const {booking_id} = await this.$api({
        action: 'carecloud_api',
        method: 'POST',
        route: 'bookings',
        data: {
          booking: {
            customer_id: customer_id,
            booking_items: [
              {
                time_slot_id: this.state.selected_time_slot,
                customer_id: customer_id,
                created_at
              }
            ],
            utm: this.resolveUTM(),
            created_at
          }
        }
      })

      pushAnalytics({
        event: 'form_reservation',
        form_registration: {
          id: booking_id,
          name: this.getBookingTicketName(this.state.selected_ticket),
          type: 'reservation',
          location: this.getBookingStoreName(this.state.selected_store),
          date: this.getOriginalTimeSlotValidFrom(this.state.selected_time_slot),
          membership: this.state.current_status || null,
          membershipid: this.state.customer_id,
          text: 'Rezervovat'
        }
      })

      void this.updateState({complete: true})
      App.$toast("Rezervace úspěšně vytvořena.")
      this.reset()

    } catch (error) {

      App.$toast(error)

    } finally {

      void this.updateState({loading_page: false})

    }
  }

  render() {
    return (
      <>
        {this.state.complete ?
          <ReservationConfirm/>
          :
          <Layout tagName="main" className="page-with-title">
            <h1>Rezervace odběru</h1>
            <Row align="center" className="margin-auto">
              <Col xs={22} md={24} xl={24}>
                <Spin spinning={this.state.loading_page} size="large">
                  <Card bordered={false} style={{maxWidth: 680}} className="card reservation">

                    {this.state.blocked_alert &&
                      <Result
                        title="Rezervaci není možné vytvořit. Účet je zablokovaný."
                        extra={
                          <Link to={"/"}>
                            <Button type="default" size="large">Zpět na přehled</Button>
                          </Link>
                        }
                      />}

                    {this.state.ongoing_alert &&
                      <Result
                        title="Již máte aktivní rezervaci."
                        extra={
                          <Link to="/reservations">
                            <Button type="default" size="large">Přehled rezervací</Button>
                          </Link>
                        }
                      />}


                    {!this.state.ongoing_alert &&
                      !this.state.blocked_alert &&
                      !this.state.complete &&
                      <Form
                        ref={this.reservationFormRef}
                        {...layout}
                        layout="horizontal"
                        labelWrap
                        colon={false}
                        onFinish={(...args) => this.handleReservationFormSubmit(...args)}>
                        <div className="form-container">
                          <Form.Item label="Zvolte si službu" name="selected_ticket">
                            <Select
                              ref={this.ticketInput}
                              suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                              placeholder="Prosím vyberte"
                              notFoundContent={emptyData}
                              size="large"
                              onChange={(...args) => this.onTicketChange(...args)}
                              options={this.state.booking_tickets.map(item => ({
                                label: item.name,
                                value: item.ticket_id
                              }))}/>
                          </Form.Item>

                          <Form.Item label="Odběrové centrum" name="selected_store">
                            <Select
                              style={{height: "48px"}}
                              placeholder="Vyberte odběrové centrum"
                              suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                              notFoundContent={emptyData}
                              size="large"
                              onChange={(...args) => this.onStoreChange(...args)}
                              options={this.state.booking_stores.map((item) => ({
                                label: item.name.split(", ").slice(1).join(", "),
                                value: item.resource_record_id
                              }))}/>
                          </Form.Item>

                          {this.state.partner_alert && <>
                            <Alert
                              message="Provádíte rezervaci u jiného partnera než obvykle. Proces odběru tedy může být odlišný."
                              type="info"
                              banner={true}
                              showIcon
                            />
                          </>}

                          {this.state.selected_store &&

                            <Form.Item label="Datum rezervace" name="selected_date" className="datepicker">
                              <DatePicker
                                placeholder="    .    ."
                                format="DD.MM.YYYY"
                                inputReadOnly={("ontouchstart" in document.documentElement)}
                                disabledDate={(current) => this.inputDatePickerDisabledDate(current)}
                                onChange={(...args) => this.onDateChange(...args)}
                                size="large"/>
                            </Form.Item>

                          }

                          {this.state.yearly_checkup_necessary &&

                            <Alert
                              message={
                                <>Máte vybrané datum, ve kterém je nutné provést roční prohlídku u lékaře (termín po {this.state.validity_of_yearly_checkup.format('D.M.YYYY')}).
                                  <br/><strong>Prosím, rezervujte si termín pro Roční prohlídku.</strong>
                                  <br/>
                                  <Button
                                    type="primary"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.setBookingToYearlyCheckUP()
                                    }}
                                    style={{width: "auto", margin: "0.5em auto"}}>
                                    Rezervovat roční prohlídku
                                  </Button>
                                </>}
                              type="warning"
                              banner={true}
                              showIcon
                            />

                          }

                          <Spin spinning={this.state.loading_timeslots} delay={200} size={"large"}>

                            {this.state.selected_store &&
                              this.state.selected_date &&
                              !this.state.yearly_checkup_necessary &&

                              <>
                                {this.state.original_time_slots.length === 0 &&
                                  !this.state.loading_timeslots &&

                                  <Alert
                                    message="Na tento den nejsou volné termíny."
                                    type="warning"
                                    banner={true}
                                    showIcon
                                    style={{marginTop: '1em', marginBottom: '0em'}}
                                  />
                                }

                                {this.state.original_time_slots.length !== 0 &&

                                  <>
                                    <Form.Item label="Vytíženost centra" className="time-availability-wrapper">
                                      <ReservationTimeAvailability date={this.state.selected_date.format("YYYY-MM-DD")} store={this.state.selected_store}/>
                                    </Form.Item>

                                    <Form.Item label="Zvolte si čas" name="selected_time_slot" className="select-date">
                                      <Select
                                        style={{width: 100}}
                                        placeholder="  :  "
                                        suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                                        notFoundContent={emptyData}
                                        size="large"
                                        disabled={this.state.original_time_slots.length === 0}
                                        onChange={(...args) => this.onTimeSlotChange(...args)}
                                        options={this.state.original_time_slots.map(item => ({
                                          label: item.range,
                                          value: item.time_slot_id,
                                          disabled: (item.free_capacity === 0)
                                        }))}/>
                                    </Form.Item>
                                  </>

                                }

                              </>

                            }

                          </Spin>
                        </div>
                        <Space style={{marginTop: '30px'}} size={[18, 20]} className="btn-container">
                          <div style={{width: 82.5, height: 1}}></div>
                          <Button htmlType="submit" type="primary" className={`btn btn-primary`}
                                  disabled={this.state.yearly_checkup_necessary}>Rezervovat {this.state.selected_ticket === AP_YEARLY_CHECKUP_BOOKING_TICKET_ID ? "prohlídku" : "odběr"}</Button>
                          <Link to="/">
                            <Button type="text" className="btn-cancel" icon={<CloseOutlined/>}>Zrušit</Button>
                          </Link>
                        </Space>
                      </Form>
                    }
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Layout>
        }
      </>

    )
  }
}